import React, { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { appTheme } from 'theme';
import { ArrowLeft as IconBack } from 'components/Icons';

export enum ButtonType {
    VALIDATION = 'VALIDATION',
    ACTION = 'ACTION',
    SECONDARY = 'SECONDARY',
    GHOST = 'GHOST',
    DISABLED = 'DISABLED',
    CONFIRMATION = 'CONFIRMATION'
}

export interface IProps {
    type?: 'submit' | 'reset' | 'button';
    onClick?: (event: MouseEvent) => void;
    className?: string;
    children: ReactNode | string;
    display?: ButtonType;
    inline?: boolean;
    disabled?: boolean;
    form?: string;
    fullWidth? : boolean, 
    onMouseDown?: (event: MouseEvent) => void;
    id?: string;
    styleSvg?: boolean;
    shortVersion?: boolean;
    testID?: string;
}

export default function Button({
    type = 'button',
    onClick,
    className,
    children,
    display = ButtonType.ACTION,
    inline = false,
    disabled,
    form,
    fullWidth = false, 
    onMouseDown,
    id,
    styleSvg = false,
    shortVersion = false,
    testID
}: IProps) {
    return <StyledButton 
      {...{ disabled, inline, type, display, onClick, className, form , fullWidth, onMouseDown, id, styleSvg, shortVersion }}
      data-test={testID}
      >
        {children}
      </StyledButton>;
}

interface IButtonProps {
    onClick?: (event: MouseEvent) => void;
    className?: string;
    disabled?: boolean;
    id?: string;
}

export function AddButton(props: IButtonProps) {
    return (
        <Add {...props} type="button">
            +
        </Add>
    );
}

export function DeleteButton(props: IButtonProps) {
    return (
        <Delete {...props} type="button">
            -
        </Delete>
    );
}

export function OKButton(props: IButtonProps) {
    return (
        <OK {...props} type="button">
            OK
        </OK>
    );
}

export function IconButton({ children, ...rest }: IButtonProps & { children: any }) {
    return (
        <Icon {...rest} type="button">
            {children}
        </Icon>
    );
}

const palette: Record<
    ButtonType,
    { color: any; bgColor: any; borderColor?: any; activeColor?: any; activeBgColor?: any }
> = {
    [ButtonType.ACTION]: {
        color: appTheme.color.common.white,
        bgColor: appTheme.color.common.blue,
        activeBgColor: appTheme.color.common.darkBlue,
    },
    [ButtonType.VALIDATION]: {
        color: appTheme.color.common.white,
        bgColor: appTheme.color.common.green,
        activeBgColor: appTheme.color.common.darkGreen,
    },
    [ButtonType.SECONDARY]: {
        color: appTheme.color.common.blue,
        borderColor: appTheme.color.common.blue,
        bgColor: appTheme.color.common.white,
        activeBgColor: appTheme.color.common.blue,
        activeColor: appTheme.color.common.white,
    },
    [ButtonType.GHOST]: {
        color: appTheme.color.common.blue,
        bgColor: appTheme.color.common.transparent,
        activeBgColor: appTheme.color.grey[1],
    },
    [ButtonType.DISABLED]: {
        color: appTheme.color.grey[4],
        bgColor: appTheme.color.common.transparent,
        activeBgColor: appTheme.color.grey[1],
    },
    [ButtonType.CONFIRMATION]: {
        color: appTheme.color.common.white,
        bgColor: appTheme.color.common.green,
        activeBgColor: appTheme.color.common.green,
    },
};

export const BaseButton = styled.button`
    height: ${({ theme }) => theme.dimension.height.element}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${({ theme }) => theme.spacing.s}px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.common.white};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    &:disabled {
        box-shadow: none;
        background-color: ${({ theme }) => theme.color.grey[1]};
        color: ${({ theme }) => theme.color.grey[4]};
        cursor: not-allowed;
    }
    &:hover:not(:disabled):not(:active) {
        box-shadow: ${({ theme }) => theme.boxShadow[0]};
    }
    &:active {
        box-shadow: none;
    }
    &:hover:not(:disabled){
        border: none;
    }
`;

interface StyledButtonProps {
    display: ButtonType;
    inline: boolean;
    fullWidth: boolean;
    id?: string;
    styleSvg?: boolean;
    shortVersion?: boolean;
}

// @ts-ignore
const StyledButton = styled(BaseButton)<StyledButtonProps>`
    height: ${({ theme, inline }) => (inline ? theme.dimension.height.inline : theme.dimension.height.element)}px;
    min-width: ${({ inline, shortVersion }) => (inline ? 50 : shortVersion ? 120 : 160)}px;
    border: ${({ display }) => (palette[display].borderColor ? `1px solid ${palette[display].borderColor}` : 'none')};
    background-color: ${({ display }) => palette[display].bgColor};
    color: ${({ display }) => palette[display].color};
    &:hover:not(:disabled),
    &:active {
        background-color: ${({ display }) =>
            palette[display].activeBgColor ? palette[display].activeBgColor : palette[display].bgColor};
        color: ${({ display }) =>
            palette[display].activeColor ? palette[display].activeColor : palette[display].color};
        
        > SVG {
          filter: ${({ styleSvg }) => styleSvg ? 'brightness(0) invert(1)' : 'none'}; 
        }
    }
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    padding: ${({ shortVersion }) => (shortVersion ? 0 : 20)}px;

`;

const Add = styled(BaseButton)`
    width: 40px;
    background-color: ${({ theme }) => theme.color.grey[0]};
    color: ${({ theme }) => theme.color.common.blue};
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
`;

const OK = styled(BaseButton)`
    width: 20px;
    background-color: ${({ theme }) => theme.color.common.blue};
    color: ${({ theme }) => theme.color.common.white};
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
`;

const Delete = styled(Add)`
    color: ${({ theme }) => theme.color.common.darkRed};
`;

const Icon = styled.button`
    padding: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
`;

export const Back = styled(IconBack)`
    display: flex;
`;
