import { QueryLoader, RoutablePanel } from 'components';
import { IRenderProps as IPanelRenderProps } from 'components/Panel';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { getGlobalArticle } from 'types/getGlobalArticle';
import { listArticleConcepts_list_edges_node_ArticleConcept as ArticleConcept } from 'types/listArticleConcepts';
import { getNodes } from 'services/queryService';
import AddOrEditArticleForm from './AddOrEditForm';

const ARTICLE_MUTATION = loader('./query/updateArticle.gql');
const ARTICLE_QUERY = loader('./query/getGlobalArticle.gql');

interface IPropsPanel extends RouteComponentProps<{ idArticle: string }> {
    beforeClose: () => void;
    parentPage: string;
}

const EditGlobalArticlePanel = ({
    match: {
        params: { idArticle },
    },
    history: { push },
    location: { search },
    beforeClose,
    parentPage
}: IPropsPanel) => {
    const [t] = useTranslation();
    const onClose = () => {
        beforeClose();
        push(`${routes.globalCatalog.list()}${search}`);
    };
    return (
        <RoutablePanel
          title={t(`page:globalCatalog.catalog.edit.title`)}
          open={!!idArticle}
          onClose={onClose}
          {...(parentPage && {testID:`${parentPage}-edit-article`})}
        >
            {({ onClose }: IPanelRenderProps) => (
                <QueryLoader
                    hasData={(data: getGlobalArticle): boolean => !!(data && data.concepts && data.article)}
                    variables={{ id: idArticle }}
                    query={ARTICLE_QUERY}
                >
                    {({ data: { article, concepts, ...rest } }: QueryResult<getGlobalArticle>) => {
                        return (
                            <Mutation
                                mutation={ARTICLE_MUTATION}
                                update={(cache: any, { data: { updateArticle } }: any) => {
                                    const cachedData = cache.readQuery({
                                        query: ARTICLE_QUERY,
                                        variables: {
                                            id: idArticle,
                                        },
                                    });
                                    cache.writeQuery({
                                        query: ARTICLE_QUERY,
                                        variables: {
                                            id: idArticle,
                                        },
                                        data: { ...cachedData, article: updateArticle },
                                    });
                                }}
                            >
                                {(updateArticle: (param: Record<'variables', any>) => Promise<any>) => (
                                    <AddOrEditArticleForm
                                        {...{
                                            onSuccess: onClose,
                                            article,
                                            concepts: getNodes(concepts) as ArticleConcept[],
                                            updateArticle,
                                            ...rest,
                                        }}
                                        {...(parentPage && {testID:`${parentPage}-edit-article`})}
                                    />
                                )}
                            </Mutation>
                        );
                    }}
                </QueryLoader>
            )}
        </RoutablePanel>
    );
};

export default withRouter(EditGlobalArticlePanel);
