import { QueryLoader } from 'components';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React, { ReactElement } from 'react';
import { RouteComponentProps, RouteProps, matchPath, Redirect, withRouter } from 'react-router-dom';
import { getItem, LocalStorageKey, removeItem, setItem } from 'services/persistentData';
import { getNodes } from 'services/queryService';
import {
    searchHolding_tokenOwner_Employee as Employee,
    searchHolding_tokenOwner_Employee_choices_edges_node,
    searchHoldingAndSite,
} from 'types/searchHolding';
import routes from './routes';
import { extractPaths } from './utils';
import { EmployeeRole } from 'types/globalTypes';

interface IProps {
    userRole?: string;
    match: {
        params: { idHolding?: string, siteId?: string };
    };
    children: (authorizedIdHolding?: string, authorizedIdSite?: string) => ReactElement | string;
}

const HoldingIdCheck = ({
    userRole,
    location: { pathname },
    history,
    match: {
        params: { idHolding, siteId },
    },
    children,
}: IProps & RouteComponentProps) => {
    let currentIdSite: string = '';
    let authSiteId: string = '';


    const SEARCHSCHOOL = loader('../query/searchHolding.gql');

    const persistedIdHoldingId = getItem(LocalStorageKey.HOLDING_ID);
    let currentIdHolding = persistedIdHoldingId || '';
    
    const paths: RouteProps[] = extractPaths(routes).map((path: string) => ({ path, exact: true, strict: false }));
    const matchingRoute = paths.find(p => !!matchPath(pathname, p));

    if (matchingRoute && matchingRoute.path && matchingRoute.path.includes(':idHolding') && idHolding) {
        currentIdHolding = idHolding;
    }
    
    return (
        <QueryLoader
            errorPolicy="all"
            variables={{ 
                search: '%',
                id: currentIdHolding,
                useId: !!currentIdHolding,
                siteId: currentIdSite,
                useSiteId: !!currentIdSite 
            }}
            query={SEARCHSCHOOL}
            displayErrorMessage={false}
        >
            {({ data }: QueryResult<searchHoldingAndSite>) => {
                const tokenOwner = data.tokenOwner as Employee;
                if (!tokenOwner) {
                    removeItem(LocalStorageKey.HOLDING_ID);
                    removeItem(LocalStorageKey.CHECKLIST_SITE_ID);
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                            }}
                        />
                    );
                }

                const holding =
                    tokenOwner.selected && tokenOwner.selected.edges[0] && tokenOwner.selected.edges[0].node;
                const holdingList = getNodes(
                    tokenOwner.choices
                ) as searchHolding_tokenOwner_Employee_choices_edges_node[];
                const authorizedHolding = holding && holding.id === currentIdHolding ? holding : holdingList[0];
                const authHoldingId = !!authorizedHolding ? authorizedHolding.id : "0";

                
                if (authHoldingId !== persistedIdHoldingId) {
                    setItem(LocalStorageKey.HOLDING_ID, authHoldingId);
                    history.replace(pathname.replace(idHolding!, authHoldingId));
                }
                
                if (!authorizedHolding && userRole !== EmployeeRole.ChecklistAdmin ) {
                    removeItem(LocalStorageKey.HOLDING_ID);
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                            }}
                        />
                    );
                }

                return children(authHoldingId, authSiteId);
            }}
        </QueryLoader>
    );
};

export default withRouter(HoldingIdCheck);
