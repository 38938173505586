/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import {
    OfferTemplateSalesType,
    OfferTemplateWithdrawalType,
    OfferTemplatePeriod,
    OfferTemplateTranslationInput,
    ArticleFamily,
    QuantityRulesInput,
    PickupPointsInput
} from './globalTypes';

// ====================================================
// GraphQL mutation operation: createOfferTemplate
// ====================================================

export interface createOfferTemplate_createOfferTemplate {
    __typename: 'OfferTemplate';
    /**
     * @see Entity
     */
    id: string;
}

export interface createOfferTemplate {
    /**
     * Create an offer template for click and collect (Admins)
     */
    createOfferTemplate: createOfferTemplate_createOfferTemplate;
}

export enum IPaymentTypes {
    Badge = 'BADGE',
    CreditCard = 'CREDIT_CARD',
    Edenred = 'EDENRED',
    OnSite = 'ON_SITE',
}

export interface createOfferTemplateVariables {
  idPos: string;
  idImage: string;
  name: string;
  description: string;
  fullDescription: string;
  salesType: OfferTemplateSalesType;
  withdrawalType: OfferTemplateWithdrawalType;
  period: OfferTemplatePeriod;
  timezone: any;
  orderStartDefault: any;
  orderEndDefault: any;
  withdrawStartDefault?: any | null;
  withdrawEndDefault?: any | null;
  maxOrdersPerSlotDefault?: number | null;
  withdrawSlotDuration?: any | null;
  minCancellationDelay?: any | null;
  minPreparationDelay?: any | null;
  published: boolean;
  translations?: OfferTemplateTranslationInput[] | null;
  selectedFamilies?: ArticleFamily[] | null;
  quantityRules?: QuantityRulesInput[] | null;
  daysInAdvanceStart: number;
  daysInAdvanceEnd: number;
  paymentTypes: IPaymentTypes[];
  pickupPoints?: (PickupPointsInput | null)[] | null;
  admission: string;
  subsidies: string;
  enableComment?: boolean;
  enableWithdrawalTypeSelection?: boolean;
  isRoomService?: boolean;
}
