import { Toggle } from 'components';
import { loader } from 'graphql.macro';
import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { getOfferState, offerPalette, OfferState } from 'services/offerService';
import { changeOfferStatus as changeOfferStatusType, changeOfferStatusVariables } from 'types/changeOfferStatus';
import { State as OfferStateLabel } from 'components/Inputs/Toggle';
import styled from 'styled-components';

const CHANGE_OFFER_STATUS_MUTATION = loader('./changeOfferStatus.gql');

interface IProps {
    offer: any;
    className?: string;
    isDaysInAdvance?: boolean;
    isRoomService?: boolean;
}

function OfferPublishToggle(props: IProps) {
    const {
        offer: { id, published, orderRange },
        className,
        isDaysInAdvance = false,
        isRoomService = false,
    } = props;
    const { t } = useTranslation();
    const [changeOfferStatus] = useMutation<changeOfferStatusType, changeOfferStatusVariables>(
        CHANGE_OFFER_STATUS_MUTATION
    );

    const onState = getOfferState({ orderRange, published: true, isDaysInAdvance: isDaysInAdvance, isRoomService });
    const offState = getOfferState({ orderRange, published: false, isDaysInAdvance: isDaysInAdvance, isRoomService });

    const onToggle = () => {
        changeOfferStatus({
            variables: {
                id,
                published: !published,
            }
        });
    };
    if (onState === OfferState.EXPIRED)
        return <Expired color={offerPalette[onState].highlighted}>{t(`schema:offer.state.${onState}`)}</Expired>;

    return (
      // @ts-ignore
        <Toggle
            className={className}
            checked={!!published}
            onChange={onToggle}
            onColor={offerPalette[onState].highlighted}
            offColor={offerPalette[offState].highlighted}
            onLabel={t(`schema:offer.state.${onState}`)}
            offLabel={t(`schema:offer.state.${offState}`)}
        />
    );
}

interface IStateProps {
    color: any;
}

// @ts-ignore
const Expired = styled(OfferStateLabel)<IStateProps>`
    max-width: 160px;
    height: 40px;
    background-color: ${({ color }) => color};
    color: ${({ theme }) => theme.color.common.white};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

export default OfferPublishToggle;
