import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { More, Check, Close } from './Icons';
import { appTheme } from 'theme';

export interface ITagOption {
    id: string;
    label: string;
    greyVariation?: boolean;
}

export function getBooleanOptions(trueLabel: string, falseLabel: string): ITagOption[] {
    return [
        { id: 'true', label: trueLabel },
        { id: 'false', label: falseLabel },
    ];
}

export function getSingleOption(trueLabel: string): ITagOption[] {
    return [
        { id: 'true', label: trueLabel },
    ];
}

export function getTypeOptions(articleLabel: string, option1: string, infoNewsLabel: string, option2: string): ITagOption[] {
    return [
        { id: option1, label: articleLabel },
        { id: option2, label: infoNewsLabel },
    ];
}

interface ITagProps {
    option: ITagOption;
    isSelected?: boolean;
    onToggle: () => void;
    fontSize?: string;
    tagTheme?: TagTheme;
    id?: string;
}

export default function Tag({ option, isSelected, onToggle, fontSize, tagTheme, id }: ITagProps) {
    const Icon = isSelected == null ? Close : isSelected ? Check : More;

    const handleTagClick = () => {
        if (isSelected !== null) {
            onToggle();
        }
    }

    return (
        <WrapperTag id={id}>
            <TagItem selected={isSelected} onClick={handleTagClick} fontSize={fontSize} tagTheme={tagTheme}>
                <span style={{display:"flex"}}><Icon color={appTheme.color.common.blue} /></span>
                {option.label}
            </TagItem>
        </WrapperTag>
    );
}

export enum TagTheme {
    default = 'default',
    darker = 'darker',
}

interface IPropsTags {
    options: ITagOption[];
    value?: string[];
    onChange: (value: string[], pressedValue: string) => void;
    className?: string;
    wrapped?: boolean;
    fontSize?: string;
    tagTheme?: TagTheme;
    testID?: string;
    clearAllWhenSelecting?: string // receives the id of the option that clear all previous selected options when selected and when selecting another options deselects this one
}

export function Tags({ value, className, options, onChange, wrapped = true, fontSize, tagTheme, testID, clearAllWhenSelecting }: IPropsTags) {
    const valueSet = new Set(value);
    const handleToggle = (item: ITagOption) => () => {
        if (valueSet.has(item.id)) {
            valueSet.delete(item.id);
        } else {
            // component has to deal with formulas special case, because it needs a different behavior, but it's being
            //  displayed as a normal field for now
            if (item.id === clearAllWhenSelecting) {
                // formula filter needs to trigger a different query, and it's not compatible to be used with the remaining filters for now
                valueSet.clear();
            }
            if (clearAllWhenSelecting && valueSet.has(clearAllWhenSelecting)) {
                // formula is not compatible with the other filters
                valueSet.delete(clearAllWhenSelecting);
            }
            valueSet.add(item.id);
        }
        onChange(Array.from(valueSet), item.id);
    };
    const tags = options.map((option, index )=> (
        <Tag id={`tag-${index}`} key={option.id} option={option} isSelected={valueSet.has(option.id)} onToggle={handleToggle(option)} fontSize={fontSize} tagTheme={tagTheme} />
    ));

    return wrapped ? <WrapperTagList
      className={className}
      {...(testID && {'data-test':`${testID}-list`})}
    >
      {tags}
    </WrapperTagList> : <>{tags}</>;
}

interface IPropsTagsEditor {
    options: ITagOption[];
    value?: string[];
    onRemove: (value: string) => void;
    className?: string;
    wrapped?: boolean;
    testID?: string;
}
export function TagsEditor({ value, className, options, onRemove, wrapped = true, testID }: IPropsTagsEditor) {
   
    const handleToggle = (item: ITagOption) => () => {
        onRemove(item.id);
    }
    const tags = options.map((option, i) => (
        <Tag key={option.id} option={option} onToggle={handleToggle(option)} />
    ));

    return wrapped ? <WrapperTagList
      className={className}
      {...(testID && {'data-test':`${testID}-editor-list`})}
      >
        {tags}
      </WrapperTagList> : <>{tags}</>;
}

interface ITagItemProps {
    selected?: boolean;
    hasError?: boolean;
    onClick: () => void;
    children: ReactNode;
    fontSize?: string;
    tagTheme?: TagTheme;
}

export function TagItem({ selected, hasError, onClick, children, fontSize = 'XS', tagTheme = TagTheme.default }: ITagItemProps) {
    return (
        <WrapperTagItem selected={selected} onClick={onClick} hasError={hasError} fontSize={fontSize} tagTheme={tagTheme}>
            {children}
        </WrapperTagItem>
    );
}

const WrapperTag = styled.div`
    padding: 5px;
`;

const WrapperTagList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
`;

interface IWrapperTagItemProps {
    selected?: boolean;
    hasError?: boolean;
    fontSize: string;
    tagTheme: TagTheme;
}
const WrapperTagItem = styled.div<IWrapperTagItemProps>`
    padding: 0 10px;
    height: 23px;
    font-size: ${({ theme, fontSize }) => theme.typography[`fontSize${fontSize}`]}px;

    color: ${({ selected, theme }) => (!!selected ? theme.color.common.blue : theme.color.grey[6])};
    background-color: ${({ selected, hasError, theme }) =>
        hasError
            ? theme.color.common.mediumRed
            : !!selected
            ? theme.color.common.lightBlue
            : theme.color.grey[1]};

    display: flex;
    align-items: center;
    & > *:first-child {
        margin-right: 5px;
    }
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    cursor: pointer;
    user-select: none;
    transition: background-color ${({ theme }) => theme.transition.duration[1]}ms,
        color ${({ theme }) => theme.transition.duration[1]}ms;

    ${({ tagTheme, selected, theme }) => tagTheme === TagTheme.darker && css`
        color: ${selected ? theme.color.common.blue : theme.color.common.darkGrey}
    `}
`;
