import React, { useState } from 'react';
import { OfferItemFormulaToSell } from 'types/globalTypes';
import MultipleToggle from '../../../../components/Inputs/MultipleToggle';


interface IOfferItemsToggleProps {
    id: string;
    toSell: OfferItemFormulaToSell;
    onToSellUpdate: (id: string, toSell: OfferItemFormulaToSell) => void;
}

const OfferItemsFormulaToggle = ({
    id,
    toSell,
    onToSellUpdate,
}: IOfferItemsToggleProps)  => {
    const [ toSellState, setToSellState ] = useState(toSell);
    const toSellList = [OfferItemFormulaToSell.DEACTIVATED, OfferItemFormulaToSell.ACTIVATED];
    const nextIndexes = {
        0: 1,
        1: 0,
    } as unknown as { number: number};
    const onChange = (value: any) => {
        if(toSellState !== value) {
            setToSellState(value);
            onToSellUpdate(id, value);
        }
    }
    return (
        <MultipleToggle
            values={toSellList}
            onChange={onChange}
            initialValue={toSellState}
            nextIndexes={nextIndexes}
            width={50}
            height={30}
            style={{
                selectedStyle: {
                    background: '#ffffff',
                    fontSize: '12px',
                    lineHeight: '32px',
                    textAlign: 'center',
                    transition: '250ms',
                },
                wrapperStyle: {
                    background: toSellState === OfferItemFormulaToSell.ACTIVATED ? '#70ad47' : '#d9d9d9',
                    padding: 0,
                },
            }}
        />
    );
}

export default OfferItemsFormulaToggle;
