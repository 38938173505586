import React, { ReactElement } from 'react';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import { QueryLoader } from 'components';
import { whoami, whoami_tokenOwner_Employee } from 'types/whoami';
import { EmployeeRole } from 'types/globalTypes';
import {Buffer} from 'buffer';

const WHOAMI_QUERY = loader('../../query/whoami.gql');

interface IUserProps {
    children: (props: QueryResult<whoami> & { user?: whoami_tokenOwner_Employee }) => ReactElement | null;
}

let user: whoami_tokenOwner_Employee = {__typename: 'Employee', id:'', isAdmin: true };

const User = ({ children }: IUserProps) => (
    <QueryLoader displayErrorMessage={false} hasData={() => true} query={WHOAMI_QUERY}>
        {(props: QueryResult<whoami>) => {
            const { data } = props;
            if (data != null && data.tokenOwner != null) {
                user = data && { ...data.tokenOwner } as whoami_tokenOwner_Employee;
                user.isAdmin = user.role === EmployeeRole.Admin;
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken != null && accessToken !== '') {
                    const payloadString = accessToken?.split('.')?.[1] as string;
                    const bufferString = Buffer.from(payloadString, 'base64').toString();

                    const payload = JSON.parse(bufferString);
                    if (payload.hasOwnProperty('https://foodi.com/isAdmin')) {
                        user = {
                          ...user, 
                          isAdmin: payload['https://foodi.com/isAdmin']
                        }
                    }
                }
            }
            return children({ ...props, user });
        }}
    </QueryLoader>
);

export default User;
