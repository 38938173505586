// @ts-nocheck
import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import styled, { ThemeProvider } from 'styled-components';
import { toast } from 'react-toastify';
import 'services/i18n';
import { client } from 'services/apollo/client';
import { appTheme, GlobalStyle } from 'theme';
import Router from 'Router';
import { GlobalLoader } from 'components/Loader';
import { CloseButton } from 'components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { BaseModalBackground, ModalProvider } from 'styled-react-modal'
import { loader } from 'graphql.macro';
import useWebSocket from 'react-use-websocket';

toast.configure({
    hideProgressBar: true,
    toastClassName: 'app-toast',
    className: 'app-toast-container',
    closeButton: <CloseButton />,
});

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => 
    props.opacity
  };
  transition: all 0.3s ease-in-out;
`;

const GET_TOGGLE_TOKEN = loader('./query/getToggleToken.gql');

const App: React.FC = () => {
    const [webSocketURL, setWebSocketURL] = React.useState("");
    const [maintenance, setMaintenance] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>();

    client.query({ query: GET_TOGGLE_TOKEN, variables: { type: 'read' } })
        .then((result) => setData(result?.data));
    
    const { sendMessage, lastMessage } = useWebSocket(webSocketURL, {}, webSocketURL !== "");

    sendMessage(JSON.stringify({ action: "retrieveFeatures", application_id: "FOODI-BACKOFFICE" }));

    React.useEffect(() => {
        const token = data?.getWsToken?.access_token;
        if (webSocketURL === "" && !!token && token !== '') {
            const url = `wss://portail.featuretoggletool.dev.devdigital.foodi.fr/?token=${token}`;
            setWebSocketURL(url);
        }
    }, [webSocketURL, data]);

    React.useEffect(() => {
        if (lastMessage) {
            const messageParse = JSON.parse(lastMessage.data);

            if (messageParse.type === 'RETRIEVE') {
                const data = messageParse.data;

                const filterSiteMaintenance = data.filter((d:any) => d.feature_name === 'SITE_MAINTENANCE')?.[0];

                if (filterSiteMaintenance !== undefined) {
                    setMaintenance(filterSiteMaintenance.isActive);
                }
            }
        }
    }, [lastMessage?.data]);
    
    return (
        <ThemeProvider theme={appTheme}>
          <ModalProvider backgroundComponent={FadingBackground}>
              <ApolloProvider client={client}>
                  <GlobalStyle />
                  <Suspense fallback={<GlobalLoader />}>
                      <Router />
                  </Suspense>
              </ApolloProvider>
          </ModalProvider>
        </ThemeProvider>
    );
};

export default App;
