import { Column, InfoLabel, PriceInputField, Tooltip, Text } from 'components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field } from 'formik';
import * as Yup from 'yup';
import { ToggleSwitchField } from 'components/Inputs/FormikFields';
import { OfferTemplateWithdrawalType } from 'types/globalTypes';
import { IPaymentTypes } from 'types/createOfferTemplate';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
const AvailablePaymentMethodes = loader('../query/availablePaymentMethodes.gql');
interface IStepProps {
    setStepSchema: Function;
    isModeEdit: boolean;
    validationSchema?: Yup.ObjectSchema<any>;
    values?: any;
    validate?: Function;
    setFieldValue?: Function;
}

const StepFour = ({ values, setStepSchema, setFieldValue, isModeEdit, ...props }: IStepProps) => {
    const { t } = useTranslation();
    const { onsite } = values;

    const { data } = useQuery(AvailablePaymentMethodes, {
        variables: { id: values.holding, withdrawalType: values.withdrawalType },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const availablePaymentMethodes = (data && data.holding && data?.holding?.availablePaymentMethodes) || [];
    const isSchool = !!data?.holding?.isSchool;

    const tableService = values.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE;
    const isCatCSI = tableService && data?.holding?.idCashSystem === 1;

    useEffect(() => {
        setStepSchema(
            Yup.object().shape({
                badge: Yup.boolean(),
                cc: Yup.boolean(),
                edenred: Yup.boolean(),
                onsite: Yup.boolean(),
                ...(onsite && {
                    admission: Yup.number(),
                    subsidies: Yup.number(),
                }),
            })
        );
    }, [setStepSchema, t, onsite]);

    useEffect(() => {
        setFieldValue && setFieldValue('badge', availablePaymentMethodes.includes(IPaymentTypes.Badge));
        isSchool && setFieldValue?.('admission', '0');
        isSchool && setFieldValue?.('subsidies', '0');
    }, [setFieldValue, availablePaymentMethodes, isSchool]);

    useEffect(() => {
        if (isSchool || isCatCSI) {
            setFieldValue?.('admission', '0');
            setFieldValue?.('subsidies', '0');
        }
    }, [setFieldValue, isSchool, isCatCSI]);

    return (
        <Wrapper>
            <SubWrapper>
                <InfoLabel fontWeight="bold" name={t(`page:admin.cnc.paymentMethod`)} />
                {availablePaymentMethodes.includes(IPaymentTypes.Badge) && (
                    <Field
                        name="badge"
                        component={ToggleSwitchField}
                        sideLabel={true}
                        onLabel={t('page:admin.cnc.badge')}
                        offLabel={t('page:admin.cnc.badge')}
                        disabled
                    />
                )}
                {availablePaymentMethodes.includes(IPaymentTypes.CreditCard) && (
                    <Field
                        name="cc"
                        component={ToggleSwitchField}
                        sideLabel={true}
                        onLabel={t('page:admin.cnc.cc')}
                        offLabel={t('page:admin.cnc.cc')}
                    />
                )}
                {availablePaymentMethodes.includes(IPaymentTypes.Edenred) && (
                    <Field
                        name="edenred"
                        component={ToggleSwitchField}
                        sideLabel={true}
                        onLabel={t('page:admin.cnc.edenred')}
                        offLabel={t('page:admin.cnc.edenred')}
                    />
                )}
                {availablePaymentMethodes.includes(IPaymentTypes.OnSite) && (
                    <Field
                        name="onsite"
                        component={ToggleSwitchField}
                        sideLabel={true}
                        onLabel={t('page:admin.cnc.onsite')}
                        offLabel={t('page:admin.cnc.onsite')}
                    />
                )}
                {onsite && !isSchool && !isCatCSI && (
                  // @ts-ignore
                    <FormLine>
                      {// @ts-ignore
                        <FormLine>
                          <Field
                              label={<TextLabel>{t('page:admin.cnc.admissionFee.title')}</TextLabel>}
                              name="admission"
                              type="numeric"
                              component={PriceInputField}
                              overrideShowRequiredAsterisk
                              infoLabel={
                                  <InfoLabel
                                      fontWeight="bold"
                                      fontSize="S"
                                      paddingBottom={8}
                                      withTooltip
                                      tooltipContent={t('page:admin.cnc.admissionFee.description')}
                                      tooltipContainerStyle={{
                                          backgroundColor: '#FFFBE0',
                                      }}
                                  />
                              }
                              labelContainerStyle={{
                                  marginBottom: 20,
                                  display: 'flex',
                              }}
                          />
                      </FormLine>
                      }

                        <FormLine ml={60}>
                            <Field
                                label={<TextLabel>{t('page:admin.cnc.ppcFee.title')}</TextLabel>}
                                name="subsidies"
                                type="numeric"
                                component={PriceInputField}
                                overrideShowRequiredAsterisk
                                infoLabel={
                                    <InfoLabel
                                        fontWeight="bold"
                                        fontSize="S"
                                        paddingBottom={8}
                                        withTooltip
                                        tooltipContent={t('page:admin.cnc.ppcFee.description')}
                                        tooltipContainerStyle={{
                                            backgroundColor: '#FFFBE0',
                                        }}
                                    />
                                }
                                labelContainerStyle={{
                                    marginBottom: 20,
                                    display: 'flex',
                                }}
                            />
                        </FormLine>
                    </FormLine>
                )}
            </SubWrapper>
            <SubWrapper />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    align-items: flex-start;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const SubWrapper = styled(Column)`
    align-items: flex-start;
    margin: 0 20px;
    width: 600px;
`;

const FormLine = styled.div`
    display: flex;
    margin-left: ${(props: { ml: number }) => props.ml}px;
`;

export const StyledTooltip = styled((props) => <Tooltip styledTooltip={true} {...props} />)`
    top: ${({ theme }) => theme.spacing.s}px;
    padding: 5px 0px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 70px;
    left: 50%;
    margin-left: -35px;
    text-align: center;
`;

const TextLabel = styled(Text)`
    white-space: pre;
    font-size: 16px;
    margin-right: 5px;
`;

export default StepFour;
